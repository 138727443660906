import React from "react"
import { Link, graphql } from "gatsby"

import { getSrc } from "gatsby-plugin-image"

import Moment from "react-moment"

import Layout from "../components/layout"
import Seo from "../components/seo"

export const query = graphql`
  query ArticleQuery($slug: String!) {
    strapiNewspage {
      strapiId
      title
      slug
    }
    allStrapiNewsPage(sort: { fields: [strapiId], order: ASC }) {
      edges {
        node {
          strapiId
          title
          slug
        }
      }
    }
    strapiArticle(slug: { eq: $slug }) {
      strapiId
      title
      updated_at
      category {
        name
        slug
      }
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              quality: 80
              breakpoints: [768, 1024, 1280]
              layout: FULL_WIDTH
            )
          }
        }
      }
      Seo {
        metaDescription
        metaTitle
        shareImage {
          image {
            localFile {
              publicURL
            }
          }
        }
      }
      content
    }
  }
`

const Article = ({ data }) => {
  const article = data.strapiArticle
  const newsPages = data.allStrapiNewsPage.edges
  const news = data.strapiNewspage

  function markupArticleContent() {
    return { __html: article.content }
  }

  return (
    <Layout>
      <Seo
        title={article.Seo.metaTitle + " | Vacational Studies"}
        description={article.Seo.metaDescription}
        image={article.Seo.shareImage.image.localFile.publicURL}
      />

      <div id="sub-header" className="bg-contrast-lower">
        <div className="container max-width-adaptive-lg margin-bottom-sm">
          <div className="subnav subnav--expanded@sm js-subnav">
            <button className="reset btn btn--subtle margin-y-sm subnav__control js-subnav__control">
              <span>Show Categories</span>
              <svg
                className="icon icon--xxs margin-left-xxs"
                aria-hidden="true"
                viewBox="0 0 12 12"
              >
                <polyline
                  points="0.5 3.5 6 9.5 11.5 3.5"
                  fill="none"
                  strokeWidth="1"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></polyline>
              </svg>
            </button>

            <div className="subnav__wrapper js-subnav__wrapper">
              <nav className="subnav__nav">
                <button
                  className="reset subnav__close-btn js-subnav__close-btn js-tab-focus"
                  aria-label="Close navigation"
                >
                  <svg className="icon" viewBox="0 0 16 16">
                    <g
                      strokeWidth="1"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit="10"
                    >
                      <line x1="13.5" y1="2.5" x2="2.5" y2="13.5"></line>
                      <line x1="2.5" y1="2.5" x2="13.5" y2="13.5"></line>
                    </g>
                  </svg>
                </button>

                <ul className="subnav__list">
                  <li className="subnav__item" key={`page__${news.slug}`}>
                    <Link
                      className="subnav__link"
                      to={`/${news.slug}`}
                      aria-current="page"
                    >
                      {news.title}
                    </Link>
                  </li>
                  {newsPages.map((newspage, i) => {
                    return (
                      <li
                        className="subnav__item"
                        key={`page__${newspage.node.slug}`}
                      >
                        <Link
                          className="subnav__link"
                          to={`/news/${newspage.node.slug}`}
                        >
                          {newspage.node.title}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <nav
        id="breadcrumbs"
        className="breadcrumbs text-sm container max-width-adaptive-lg margin-y-sm"
        aria-label="Breadcrumbs"
      >
        <ol className="flex flex-wrap gap-xxs">
          <li className="breadcrumbs__item">
            <Link className="color-inherit" to={`/${news.slug}`}>
              {news.title}
            </Link>
            <span
              className="color-contrast-low margin-left-xxs"
              aria-hidden="true"
            >
              /
            </span>
          </li>

          <li className="breadcrumbs__item">
            <Link
              className="color-inherit"
              to={`/category/${article.category.slug}`}
            >
              {article.category.name}
            </Link>
            <span
              className="color-contrast-low margin-left-xxs"
              aria-hidden="true"
            >
              /
            </span>
          </li>

          <li className="breadcrumbs__item" aria-current="page">
            {article.title}
          </li>
        </ol>
      </nav>

      <div id="article" className="position-relative z-index-1">
        <div className="container max-width-adaptive-lg">
          <article className="padding-bottom-lg">
            <div className="t-article-v2__hero">
              <div
                className="t-article-v2__cover z-index-1"
                aria-hidden="true"
                style={{
                  backgroundImage: `url(${getSrc(
                    article.image.localFile.childImageSharp.gatsbyImageData
                  )})`,
                  zIndex: "-1",
                }}
              >
                <div className="padding-y-xxxl"></div>
              </div>

              <div className="t-article-v2__intro container max-width-adaptive-sm margin-bottom-lg z-index-2">
                <div className="text-component text-center">
                  <h1 className="text-xxxl">{article.title}</h1>
                  <p className="text-sm">
                    Updated on:{" "}
                    <Moment format="D MMMM YYYY">{article.updated_at}</Moment>
                  </p>
                </div>

                <div
                  className="t-article-v2__divider margin-top-lg"
                  aria-hidden="true"
                >
                  <span></span>
                </div>
              </div>
            </div>

            <div className="container max-width-adaptive-sm margin-bottom-xl">
              <div className="text-component line-height-lg text-space-y-md">
                <div dangerouslySetInnerHTML={markupArticleContent()} />
              </div>
            </div>
          </article>
        </div>
      </div>
    </Layout>
  )
}

export default Article
